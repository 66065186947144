<template>
  <component :is="'style'" v-html="compstyle">
  </component>
  <div class="tablezone" v-if="existCondition">
    <div class="uptable-controls tablezone__controls">
      <Btn :small="true"
        v-if="(daycount || tableparticular) && ((reqcount > 100) || (tableparticulardata?.length > 100))"
        @click.prevent.stop="toggleLimit" class="tablesave btn btn_blue">{{ limit ? 'Показать все' : 'Показать 100' }}
      </Btn>
      <Btn :small="true" v-if="(reqcount && daycount) || (tableparticular && (tableparticulardata?.length))"
        class="tablesave btn btn_blue" @click="save">Сохранить таблицу</Btn>
    </div>
    <div style="overflow: visible; width: 100%; padding-top: 30px; margin-top: -30px;">
      <table v-if="(reqcount && daycount) && !tableparticular" class="table" ref="table" :data-cols-width="getColWidth">
        <tr :class="{ 'inversed': sort.inverse == -1 }">
          <td :class="{ 'active': sort.col == 0 }" @click="clicksort(0)" data-a-h="center" data-a-v="middle">
            <b>Запрос</b>
          </td>
          <td v-if="group === null" data-a-h="center" data-a-v="middle"><b>Группа</b></td>
          <td
            :class="{ 'active': sort.col == index + 1, 'datecell': true, 'filtered': (parsedFilters[index] !== false && parsedFilters[index] !== '') }"
            v-for="date, index in tabledates" :key="date" @click="clicksort(index + 1)" data-a-h="center"
            data-a-v="middle">
            <b>{{ date }}</b>
            <button class="datecell__delete" v-if="filtersvisible" @click="deleteByDate(index)">&times;</button>
            <input @click.stop class="datecell__input" :class="{ 'datecell__input_broken': filterParseError[index] }"
              v-if="filtersvisible" type="text" v-model="filters[index]">
            <div @click.stop class="datecell__count" v-if="filtersvisible" type="text">{{ passedFilterCounts[index] }}
            </div>
          </td>
        </tr>
        <tr v-if="topCondition">
          <td class="link link_special" data-a-v="middle">
            <div>Топ-10</div>
          </td>
          <td class="group link" v-if="group === null" data-a-v="middle">
            <div> </div>
          </td>
          <td v-for="(date, index) in tabledates" :key="date + ' - ' + project.name" :data-a-h="'center'"
            data-a-v="middle" :data-t="'n'">{{ top10Counts[index] }}</td>
        </tr>
        <tr v-if="topCondition">
          <td class="link link_special" data-a-v="middle">
            <div>Топ-50 (11:50)</div>
          </td>
          <td class="group link" v-if="group === null" data-a-v="middle">
            <div> </div>
          </td>
          <td v-for="(date, index) in tabledates" :key="date + ' - ' + project.name" :data-a-h="'center'"
            data-a-v="middle" :data-t="'n'">{{ top50Counts[index] }}</td>
        </tr>
        <tr v-if="topCondition">
          <td class="link link_special" data-a-v="middle">
            <div>Топ-100 (51:100)</div>
          </td>
          <td class="group link" v-if="group === null" data-a-v="middle">
            <div> </div>
          </td>
          <td v-for="(date, index) in tabledates" :key="date + ' - ' + project.name" :data-a-h="'center'"
            data-a-v="middle" :data-t="'n'">{{ top100Counts[index] }}</td>
        </tr>
        <tr v-for="request in limitedrequests" :key="request + ' - ' + project.name">
          <td class="link" data-a-v="middle">
            <div>{{ group === null ? JSON.parse(request)[0] : request }}</div>
          </td>
          <td class="group link" v-if="group === null" data-a-v="middle">
            <div>{{ JSON.parse(request)[1] }}</div>
          </td>
          <td v-for="(date, index) in tabledates" :key="date + ' - ' + project.name"
            :data-a-h="columntype == 'number' ? 'center' : 'left'" data-a-v="middle"
            :data-t="(columntype == 'number' && (tabledata[request][date] || tabledata[request][date] === 0)) ? 'n' : 's'"
            :class="getColor(request, date, index)">{{ tabledata[request][date] }}</td>
        </tr>
      </table>
      <table v-if="tableparticular && pardate && tableparticulardata" class="table" ref="table"
        :data-cols-width="getColWidth">
        <tr :class="{ 'inversed': sort.inverse == -1 }">
          <td :class="{ 'active': sort.col == 0 }" @click="clicksort(0)" data-a-h="center" data-a-v="middle">
            <b>Запрос</b>
          </td>
          <td v-if="group === null" data-a-h="center" data-a-v="middle"><b>Группа</b></td>
          <td
            :class="{ 'active': sort.col == index + 1, 'datecell': true, 'datecell_tiny': true, 'datecell_colname': Role == 2 }"
            v-for="col, index in filteredcolnames" :key="col" @click="clicksort(index + 1)" data-a-h="center"
            data-a-v="middle">
            <b>{{ col }}</b>
            <span class="datecell__orderhash" @click.stop.prevent="setColOrder(col)" v-if="Role == 2">#
              <span class="datecell__orderhash-number" v-if="parseInt(project.colnames[encodeKey(col)])">{{
                project.colnames[encodeKey(col)]
              }}</span>
            </span>
          </td>
        </tr>
        <tr v-for="line in sortedtableparticulardata" :key="line.phrase">
          <td class="link" data-a-v="middle">
            <div>{{ line.phrase }}</div>
          </td>
          <td class="group link" v-if="group === null" data-a-v="middle">
            <div>{{ line.group }}</div>
          </td>
          <td v-for="(col, i) in filteredcolnames" :key="col + ' - ' + project.name"
            :data-a-h="filteredcoltypes[i] == 'number' ? 'center' : 'left'" data-a-v="middle"
            :data-t="(filteredcoltypes[i] == 'number' && (line.value[col] || line.value[col] === 0)) ? 'n' : 's'">{{
              line.value[col] }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Btn from './buttons/Btn.vue';
import ExcelJS from 'exceljs';

export default {
  components: {
    Btn
  },
  props: {
    pardate: {},
    pardata: {}
  },
  data: () => ({
    limit: 100,
    filtersvisible: true,
    filters: [],
    sort: {
      inverse: 1,
      col: 0,
      colname: null
    }
  }),
  mounted() {
    console.log(this.project)
  },

  computed: {
    compstyle() {
      let res = '', shift = (this.group === null) ? 3 : 2
      if (!this.tableparticular)
        return ''
      for (let i = 0; i < this.filteredcolnames.length; i++) {
        //let c = this.filteredcolnames[i]
        let type = this.filteredcoltypes[i]

        if ((type == 'string')) {
          res += `
              .table tr td:nth-child(${i + shift}) {
                padding-right: 5px !important;
                padding-left: 5px !important;
                font-size: 11px;
                font-weight: 600;
                white-space: normal;
                letter-spacing: -0.07em;
              }\n
            `
        }
      }
      return res
    },
    existCondition() {
      return (this.tabledates && this.tabledates.length && this.tabledata && !this.tableparticular)
        || (this.tableparticular && this.pardate && this.tableparticulardata)
    },
    ...mapState(['column', 'project', 'tabledates', 'tabledata', 'columntype', 'topCondition', 'group', 'tableparticular', 'tableparticulardata', 'Role']),
    colnames() {
      if (!this.project || !this.project.colnames)
        return []
      let vals = [], lefts = []
      for (let key in this.project.colnames) {
        if (parseInt(this.project.colnames[key]))
          vals.push({ name: key, v: parseInt(this.project.colnames[key]) })
        else
          lefts.push(key)
      }
      vals.sort((a, b) => a.v - b.v)
      vals = vals.map(el => el.name)
      return [...vals, ...lefts].map(el => this.decodeKey(el))
    },
    coltypes() {
      let res = []
      if (!this.tableparticulardata)
        return []
      for (let col of this.colnames) {
        let type
        for (let line of this.tableparticulardata) {
          let v = line.value[col]
          if (!type && (v || v === 0) && (v != '-') && (v != '‒') && (v != '—')) {
            type = true
            res.push(typeof (line.value[col]))
            break
          }
        }
        if (!type) {
          type = true
          res.push('empty')
        }
      }
      return res
    },
    filteredcoltypes() {
      let res = this.coltypes.filter(el => el != 'empty')
      return res
    },
    filteredcolnames() {
      return this.colnames.filter((el, i) => this.coltypes[i] != 'empty')
    },
    //eslint-disable-next-line
    colorCondition() {
      if (['Позиция [Google]', 'Позиция [Yandex]'].includes(this.column.trim()))
        return true
      return false
    },
    sortedtableparticulardata() {
      if (!this.tableparticulardata)
        return []

      let res = this.tableparticulardata.map(el => el)

      if (this.sort.col != 0) {
        const type = this.filteredcoltypes[this.sort.col - 1]
        const col = this.filteredcolnames[this.sort.col - 1]
        if (type == 'string')
          res.sort((a, b) => {
            let v1 = a.value[col], v2 = b.value[col]
            if (typeof (v1) != 'string') v1 = ''
            if (typeof (v2) != 'string') v2 = ''
            return v1.localeCompare(v2) * this.sort.inverse
          })
        else
          res.sort((a, b) => {
            let v1 = a.value[col], v2 = b.value[col]
            if (typeof (v1) != 'number') v1 = -Infinity
            if (typeof (v2) != 'number') v2 = -Infinity
            return (v1 - v2) * this.sort.inverse
          })
      }
      else
        res.sort((a, b) => (a.phrase.localeCompare(b.phrase)) * this.sort.inverse)

      if (this.limit)
        return res.slice(0, this.limit)
      return res

    },
    top10Counts() {
      let arr = new Array(this.tabledates.length).fill('')
      if (!this.topCondition)
        return arr
      arr.fill(0)
      for (let i = 0; i < this.tabledates.length; i++) {
        let d = this.tabledates[i]
        for (let r of this.requests)
          if ((this.tabledata[r][d] > 0) && (this.tabledata[r][d] <= 10))
            arr[i]++
      }

      return arr
    },
    top50Counts() {
      let arr = new Array(this.tabledates.length).fill('')
      if (!this.topCondition)
        return arr
      arr.fill(0)
      for (let i = 0; i < this.tabledates.length; i++) {
        let d = this.tabledates[i]
        for (let r of this.requests)
          if ((this.tabledata[r][d] > 10) && (this.tabledata[r][d] <= 50))
            arr[i]++
      }
      return arr
    },
    top100Counts() {
      let arr = new Array(this.tabledates.length).fill('')
      if (!this.topCondition)
        return arr
      arr.fill(0)
      for (let i = 0; i < this.tabledates.length; i++) {
        let d = this.tabledates[i]
        for (let r of this.requests)
          if ((this.tabledata[r][d] > 50) && (this.tabledata[r][d] <= 100))
            arr[i]++
      }
      return arr
    },
    requests() {
      if (!this.tabledata)
        return []
      return Object.keys(this.tabledata)
    },
    sortedrequests() {
      let requests = this.requests.map(el => el)
      let baseRequests = {}
      if (this.group === null)
        for (let r of requests) {
          if (baseRequests[r])
            alert(`Ошибка: обнаружен дубликат запроса (${r})`)
          else
            baseRequests[r] = JSON.parse(r)[0]
        }
      if (this.sort.col == 0) {
        if (this.group === null)
          return requests.sort((a, b) => (baseRequests[a].localeCompare(baseRequests[b])) * this.sort.inverse)
        else
          return requests.sort((a, b) => (a.localeCompare(b)) * this.sort.inverse)
      }
      let cd = this.tabledates[this.sort.col - 1]
      if (this.columntype == 'string')
        return requests.sort((a, b) => ((this.tabledata[a][cd] + '').localeCompare((this.tabledata[b][cd] + ''))) * this.sort.inverse)
      if (this.columntype == 'number')
        return requests.sort((a, b) => {
          let v1 = this.tabledata[a][cd], v2 = this.tabledata[b][cd]
          if (typeof (v1) != 'number') v1 = -Infinity
          if (typeof (v2) != 'number') v2 = -Infinity
          return (v1 - v2) * this.sort.inverse
        })
      return requests
    },
    filteredRequests() {
      let filters = this.parsedFilters.map((rule, index) => {
        if (rule === false || rule === '') return false
        return ({ value: rule, index })
      }).filter(el => el)
      console.log(this.filters)
      console.log(filters)
      return this.sortedrequests.filter(el => {
        for (let filter of filters) {
          let cd = this.tabledates[filter.index]
          let value = this.tabledata[el][cd]
          let result = this.filterValue(value, filter.value)
          if (!result) return false
        }
        return true
      })
    },
    limitedrequests() {
      if (this.limit)
        return this.filteredRequests.slice(0, this.limit)
      return this.filteredRequests
    },
    reqcount() {
      return this.requests.length
    },
    daycount() {
      return this.tabledates.length
    },
    parsedFilters() {
      return this.filters.map(el => this.parseFilter(el))
    },
    filterParseError() {
      if (this.columntype == 'number') {
        return this.parsedFilters.map(el => el === false ? true : false)
      } else {
        return this.filters.map(() => false)
      }
    },
    getColWidth() {
      let base = "40"
      if (this.group === null)
        base += ",30"
      if (!this.tableparticular) {
        if (this.columntype == 'number') {
          return base + this.tabledates.map(() => ",10").join("")
        } else {
          return base + this.tabledates.map(() => ",30").join("")
        }
      } else {
        return base + this.filteredcoltypes.map((type) => {
          return (type == 'number') ? ",10" : ",30"
        }).join("")
      }
    },
    passedFilterCounts() {
      let filters = this.parsedFilters.map((rule, index) => {
        if (rule === false || rule === '') return false
        return ({ value: rule, index })
      }).filter(el => el)
      let res = new Array(this.parsedFilters.length).fill(0)
      this.sortedrequests.forEach(el => {
        for (let filter of filters) {
          let cd = this.tabledates[filter.index]
          let value = this.tabledata[el][cd]
          let result = this.filterValue(value, filter.value)
          if (result) res[filter.index]++
        }
      })
      return res
    }
  },
  methods: {
    async setColOrder(col) {
      const priority = prompt('Задайте приоритет столбца')
      console.log(this.$store.state)
      console.log(this.$store.state.project)

      this.$store.commit('toggleLoad', 'setColOrder')
      await fetch(this.$store.state.url + 'setorder', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },

        body: JSON.stringify({ project: this.$store.state.project.name, orders: { [col]: priority } }),
      })
        .then(async res => {
          if (!res.ok) throw (await res.text())
          await this.$store.dispatch('updateProjects')
        })
        .catch(err => { alert(err); console.error(err) })
      this.$store.commit('toggleLoad', 'setColOrder')
    },
    encodeKey(key) {
      return key.replace(/\\/g, "\\\\").replace(/\$/g, "\\u0024").replace(/\./g, "\\u002e")
    },
    decodeKey(key) {
      return key.replace(/\\u002e/g, ".").replace(/\\u0024/g, "$").replace(/\\\\/g, "\\")
    },
    getColor(request, date, index) {
      let tabledata = this.tabledata
      let tabledates = this.tabledates
      if (!this.colorCondition || !tabledates[index + 1])
        return ''
      let vals = [parseInt(tabledata[request][date]), parseInt(tabledata[request][tabledates[index + 1]])]
      if (vals.includes(NaN))
        return ''
      for (let i in vals)
        if (vals[i] == -1)
          vals[i] = 300
      if (vals[0] < vals[1])
        return 'good'
      if (vals[0] > vals[1])
        return 'bad'
    },
    async save() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Данные');

      // Add headers
      let headers = [];
      if (!this.tableparticular) {
        headers = ['Запрос'];
        if (this.group === null) {
          headers.push('Группа');
        }
        headers.push(...this.tabledates);
      } else {
        headers = ['Запрос'];
        if (this.group === null) {
          headers.push('Группа');
        }
        headers.push(...this.filteredcolnames);
      }
      worksheet.addRow(headers);

      // Add data rows
      if (!this.tableparticular) {
        if (this.topCondition) {
          worksheet.addRow(this.createTopRow('Топ-10', this.top10Counts));
          worksheet.addRow(this.createTopRow('Топ-50 (11:50)', this.top50Counts));
          worksheet.addRow(this.createTopRow('Топ-100 (51:100)', this.top100Counts));
        }

        this.filteredRequests.forEach(request => {
          const row = [];
          row.push(this.group === null ? JSON.parse(request)[0] : request);
          if (this.group === null) {
            row.push(JSON.parse(request)[1]);
          }
          this.tabledates.forEach(date => {
            let value = this.tabledata[request][date];
            if (this.columntype === 'number') {
              const num = Number(value);
              value = isNaN(num) ? value : num;
            }
            row.push(value);
          });
          worksheet.addRow(row);
        });
      } else {
        const data = [...this.tableparticulardata];
        if (this.sort.col !== 0) {
          const colIndex = this.sort.col - 1;
          const col = this.filteredcolnames[colIndex];
          const type = this.filteredcoltypes[colIndex];
          if (type === 'string') {
            data.sort((a, b) => {
              const v1 = a.value[col] || '';
              const v2 = b.value[col] || '';
              return v1.localeCompare(v2) * this.sort.inverse;
            });
          } else {
            data.sort((a, b) => {
              const v1 = a.value[col] !== undefined ? a.value[col] : -Infinity;
              const v2 = b.value[col] !== undefined ? b.value[col] : -Infinity;
              return (v1 - v2) * this.sort.inverse;
            });
          }
        } else {
          data.sort((a, b) => a.phrase.localeCompare(b.phrase) * this.sort.inverse);
        }

        data.forEach(line => {
          const row = [];
          row.push(line.phrase);
          if (this.group === null) {
            row.push(line.group);
          }
          this.filteredcolnames.forEach((col, index) => {
            const type = this.filteredcoltypes[index];
            let value = line.value[col];
            if (type === 'number') {
              const num = Number(value);
              value = isNaN(num) ? value : num;
            }
            row.push(value);
          });
          worksheet.addRow(row);
        });
      }

      // Set column widths
      const colWidths = this.getColWidth.split(',').map(w => parseInt(w, 10));
      worksheet.columns = headers.map((header, index) => ({
        header,
        width: colWidths[index] || 10, // Default width if not specified
      }));

      // Generate and download the file
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${this.project.name}.xlsx`;
      a.click();
      URL.revokeObjectURL(url);
    },
    createTopRow(label, counts) {
      const row = [label];
      if (this.group === null) {
        row.push('');
      }
      counts.forEach(count => {
        row.push(typeof count === 'number' ? count : Number(count) || 0);
      });
      return row;
    },
    // 
    parseNumberInput(v) {
      if (v == '') return ''
      if (v == parseInt(v))
        return parseInt(v)
      let arr = v.split(':')
      if (arr.length != 2) return false
      if (arr[0] != parseInt(arr[0])) return false
      if (arr[1] != parseInt(arr[1])) return false
      return arr.map(el => parseInt(el))
    },
    parseStringInput(v) {
      return v
    },
    filterValue(value, filter) {
      if ((typeof (filter) == 'string') || (typeof (filter) == 'number' && !isNaN(filter))) {
        if (value == filter) return true
        if (typeof (filter) == 'string')
          if ((value + '').includes(filter + '')) return true
          else return false
      } else if (Array.isArray(filter) && filter.length == 2) {
        value = parseInt(value)
        if (isNaN(value)) return false
        let min = filter[0], max = filter[1]
        if (value < min || value > max) return false
        return true
      } else {
        console.error('Неверный фильтр: ' + filter)
        return false
      }
    },
    parseFilter(v) {
      if (this.columntype == 'number')
        return this.parseNumberInput(v)
      else
        return this.parseStringInput(v)
    },
    deleteByDate(index) {
      this.$store.dispatch('deleteByDate', index)
    },
    changeInverse() {
      this.sort.inverse *= -1
    },
    clicksort(col) {
      if (col == this.sort.col)
        this.changeInverse()
      else {
        this.sort.col = col
        this.sort.colname = this.filteredcolnames[col - 1] || null
        console.log(this.sort)
      }
    },
    fixSort() {
      console.log('fixsort')
      if (!this.sort.colname)
        return
      let v = this.filteredcolnames[this.sort.col - 1]
      if ((v !== this.sort.colname)) {
        console.log(3)
        let index = this.filteredcolnames.indexOf(this.sort.colname)
        if (index != -1) {
          this.sort.col = index + 1
        }
        else {
          this.sort.col = 0
          this.sort.colname = null
        }
      }
    },
    toggleLimit() {
      if (this.limit)
        this.limit = 0;
      else
        this.limit = 100;
    }
  },
  watch: {
    tabledates: {
      deep: true,
      handler(v) {
        this.filters = new Array(v.length).fill('')
      }
    },
    tableparticular(v, old) {
      if (v == old)
        return
      this.sort = {
        inverse: 1,
        col: 0
      }
    },
    filteredcolnames() {
      this.fixSort()
    }
  }
}
</script>

<style lang="sass" scoped>
$danger: #dc3545
$success: #198754
$primary: #0d6efd
.uptable-controls
  display: flex
  gap: 20px

.tablezone
  &__controls
      margin-bottom: 20px
.table  
  display: block
  max-width: 100%
  font-size: 14px
  margin-bottom: 30px
  th, td
    border: 2px solid rgba(darken($primary, 20%), .2)
  td:first-child
    width: 300px
    min-width: 300px
    max-width: 300px
  td.bad
    background: rgba(255,200,200) !important
  td.good
    background: rgb(200,255,200) !important
  tr
    font-weight: 500
    white-space: nowrap
    &:first-child td
      background: #eaeaea
  td.active
    background: white !important
    position: relative
    padding-right: 15px
    &::after
      position: absolute
      content: '↑'
      right: 2px
      font-size: 18px
      top: 50%
      transform: translateY(-65%)
      transform-origin: center center
  td.filtered
    outline: 2px solid violet
    .datecell__count
      opacity: 1
      pointer-events: auto
  .inversed .active::after
    content: '↓'

  td
    padding: 3px 10px
    position: relative
    .tooltip
      pointer-events: none !important
      background: rgba(black, .9)
      color: white
      padding: 5px 10px
      border-radius: 5px
      top: calc(100% + 5px)
      left: 50%
      transform: translateX(-50%)
      position: absolute
      opacity: 0
      transition: .2s ease-out
      width: max-content
      span
        display: block
        max-width: 300px
    &:hover .tooltip
      opacity: 1
    &:not(:first-child)
      text-align: center
    &.group
      text-align: left
      width: 150px !important
      max-width: 150px !important
      min-width: 150px !important
      position: relative !important
    &.link
      &:first-child
        z-index: 3
      z-index: 2
      max-width: 300px
      overflow: hidden
      position: sticky
      left: 0
      background: white !important
      &_special
        &, &~td
          background: rgba(235,255,255,1) !important
      &:hover 
        overflow: visible
        padding: 0
        div
          position: relative
          width: fit-content
          padding: 3px 10px
          z-index: 1
          background: rgba(255,255,255,.8)
.datecell
  position: sticky !important
  top: 0
  z-index: 2
  &__orderhash
    cursor: pointer
    position: absolute
    left: 0
    top: 0
    &-number
      font-weight: 700
      color: color.adjust(colors.$blue, $saturation: -40%)
  &_colname
    padding-top: 13px !important
  &_tiny
    padding-right: 5px !important
    padding-left: 5px !important
    font-size: 11px
    white-space: normal
    letter-spacing: -0.07em
  &__delete
    width: 20px
    background: rgba(255,255,255,.05)
    border: none
    position: absolute
    pointer-events: none
    opacity: 0
    bottom: 100%
    line-height: 0
    right: -11px
    z-index: 100
    background: rgba(0,0,0,.5)
    width: 17px
    height: 17px
    border-radius: 9999px
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    font-size: 16px
    font-weight: 700
    color: red
    padding: 3px
    pointer-events: none
    transition: opacity .3s ease-out
    cursor: pointer
    opacity: 0
  &:hover &__input, &:focus-within &__input
    pointer-events: auto
    opacity: 1
  &__count
    transition: opacity .2s ease-out
    opacity: 0
    pointer-events: none
    text-align: center
    width: auto
    min-width: 30px
    padding: 2px 8px
    position: absolute
    left: 50%
    transform: translateX(-50%)
    bottom: calc(100% + 5px)
    border: none
    background: white
    border: 2px solid violet
  &__input
    transition: opacity .2s ease-out
    opacity: 0
    pointer-events: none
    width: 100px
    position: absolute
    right: 100%
    top: 0
    bottom: 0
    border: none
    background: white
    border: 2px solid black
    &_broken
      border: 2px solid red !important
  &:hover &__delete 
    opacity: 1
    pointer-events: auto
  &:hover &__delete:hover
    background: black
</style>